import React from 'react'
import styled from '@emotion/styled'

export const Slider = props => (
  <StyledInput type="range" {...props}/>
)

const knobStyle = `
  width: 16px;
  height: 16px;

  background-color: var(--color-main, black);
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
`

const trackStyle = `
  width: 100%;
  height: 4px;

  border-radius: 1px;
  background-color: var(--color-bg-dark, gray);

  cursor: pointer;
`

const StyledInput = styled.input`
  appearance: none;
  width: 100%;
  background: transparent;
  margin: 8px 0;
  box-sizing: border-box;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top: -6px;
  }

  &::-moz-range-thumb {
    ${knobStyle}
  }

  &::-webkit-slider-thumb {
    ${knobStyle}
  }

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    ${trackStyle}
  }

  &::-moz-range-track {
    ${trackStyle}
  }
`
