import React from 'react'
import styled from '@emotion/styled'

export const Manipulator = ({
  onScale,
  onRotate,
  onCommit,
  scale,
  visible
}) => {
  if (!visible) {
    return null
  }

  return (
    <Container
      style={{
        width: `${100 * scale}%`,
        height: `${100 * scale}%`,
        transform: `translate(-50%, -50%) scale(${1 / scale})`
      }}
    >
      <LeftTop />
      <RightTop />
      <RightBottom />
      <LeftBottom />
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border: 1px dashed var(--color-bg-light, white);
`

const Handle = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;

  border-radius: 50%;
  background-color: var(--color--bg-light, white);
`

const LeftTop = styled(Handle)`
  top: -8px;
  left: -8px;
`

const RightTop = styled(Handle)`
  right: -8px;
  top: -8px;
`

const RightBottom = styled(Handle)`
  right: -8px;
  bottom: -8px;
`

const LeftBottom = styled(Handle)`
  left: -8px;
  bottom: -8px;
`
