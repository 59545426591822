import React from 'react'
import styled from '@emotion/styled'

export const Button = props => (
  <StyledAnchor role="button" {...props}/>
)

const StyledAnchor = styled.a`
  display: inline-block;
  padding: 8px 16px;
  margin: 0 8px;

  border-radius: 4px;
  color: var(--color-bg-light, white);
  background-color: var(--color-main, black);

  cursor: pointer;
  user-select: none;
  transition: filter 0.1s ease;

  &:hover {
    filter: brightness(1.3) drop-shadow(0 0 6px rgba(0, 0, 0, 0.25));
  }

  &:active {
    filter: brightness(0.9) drop-shadow(0 0 2px rgba(0, 0, 0, 0.25));
  }

  &[disabled] {
    background-color: gray;
    filter: none !important;
  }
`
