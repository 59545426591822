import React, { Component } from 'react'
import styled from '@emotion/styled'

import { DraggableCore } from 'react-draggable'

import { Controls } from 'components/Controls'
import { Manipulator } from 'components/Manipulator'
import { MoveController } from 'components/MoveController'
import { Slider } from 'components/Slider'

export class Preview extends Component {
  state = {
    scale: 1,
    offsetX: 0,
    offsetY: 0,
    stack: [
      {
        degree: 0,
        x: 0,
        y: 0,
        scale: 1,
        pending: false
      }
    ],
    pointer: 0
  }

  get canUndo() {
    return this.state.pointer > 0
  }

  get canRedo() {
    return this.state.pointer < this.state.stack.length - 1
  }

  commit = fn => {
    this.setState(state => {
      const { stack, pointer } = state

      const newStack = fn(stack[pointer])

      return {
        stack: [...stack.slice(0, pointer + 1), newStack],
        pointer: pointer + 1
      }
    })
  }

  rotate = degree => {
    this.commit(stack => ({
      ...stack,
      degree: stack.degree + degree
    }))
  }

  moveX = x => {
    this.commit(stack => ({
      ...stack,
      x: stack.x + x
    }))
  }

  moveY = y => {
    this.commit(stack => ({
      ...stack,
      y: stack.y + y
    }))
  }

  drag = (rawX, rawY) => {
    const [x, y] = [rawX / this.state.scale, rawY / this.state.scale]

    this.setState(({ stack, pointer }) => {
      if (stack[pointer].pending) {
        return {
          stack: stack.map((s, i) =>
            i !== pointer ? s : { ...s, x: s.x + x, y: s.y + y }
          )
        }
      }

      return {
        stack: [
          ...stack.slice(0, pointer + 1),
          {
            ...stack[pointer],
            x: stack[pointer].x + x,
            y: stack[pointer].y + y,
            pending: true
          }
        ],
        pointer: pointer + 1
      }
    })
  }

  scale = scale => {
    this.setState(({ stack, pointer }) => {
      if (stack[pointer].pending) {
        return {
          stack: stack.map((s, i) => (i !== pointer ? s : { ...s, scale }))
        }
      }

      return {
        stack: [
          ...stack.slice(0, pointer + 1),
          { ...stack[pointer], scale, pending: true }
        ],
        pointer: pointer + 1
      }
    })
  }

  commitPending = () => {
    this.setState(({ stack, pointer }) => ({
      stack: stack.map(s => (s.pending ? { ...s, pending: false } : s))
    }))
  }

  undo = () => {
    this.setState(state => {
      if (state.pointer <= 0) {
        return state
      }

      return {
        pointer: state.pointer - 1
      }
    })
  }

  redo = () => {
    this.setState(state => {
      if (state.pointer >= state.stack.length - 1) {
        return state
      }

      return {
        pointer: state.pointer + 1
      }
    })
  }

  reset = () => {
    this.setState({
      pointer: 0
    })
  }

  changePreviewScale = scale => {
    this.setState({ scale })
  }

  dragOffsetController = (ev, { deltaX, deltaY }) => {
    this.setState(state => {
      return {
        offsetX: state.offsetX + deltaX,
        offsetY: state.offsetY + deltaY
      }
    })
  }

  render() {
    const { stack, pointer, scale: previewScale, offsetX, offsetY } = this.state

    const { x, y, degree, scale, pending } = stack[pointer]

    const objStyle = {
      transform: `translate(${x}px, ${y}px) scale(${scale}) rotate(${degree}deg)`
    }

    return (
      <Container>
        <LayoutArea>
          <Canvas
            style={{
              transform: `translate(${offsetX}px, ${offsetY}px) scale(${previewScale})`
            }}
          >
            <DraggableCore
              onDrag={(ev, info) => this.drag(info.deltaX, info.deltaY)}
              onStop={this.commitPending}
            >
              <Obj style={objStyle}>
                :)
                <Manipulator scale={scale} visible={!pending} />
              </Obj>
            </DraggableCore>
          </Canvas>
          <ScaleSlider
            value={previewScale}
            onChange={ev =>
              this.changePreviewScale(parseFloat(ev.target.value))
            }
            min={0.5}
            max={2}
            step={0.01}
          />
          <DraggableCore onDrag={this.dragOffsetController}>
            <div>
              <OffsetController />
            </div>
          </DraggableCore>
        </LayoutArea>
        <Controls
          scale={scale}
          canUndo={this.canUndo}
          canRedo={this.canRedo}
          onRotate={this.rotate}
          onScale={this.scale}
          onCommitScale={this.commitPending}
          onMoveX={this.moveX}
          onMoveY={this.moveY}
          onRedo={this.redo}
          onUndo={this.undo}
          onReset={this.reset}
        />
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: var(--color-bg-light, white);
`

const LayoutArea = styled.div`
  position: relative;
`

const Canvas = styled.div`
  position: relative;
  width: 100mm;
  height: 150mm;

  background-color: var(--color-bg-dark, gray);
`

const Obj = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25mm;
  height: 25mm;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  color: var(--color-bg-light, white);
  background-color: var(--color-sub, red);

  cursor: pointer;
`

const ScaleSlider = styled(Slider)`
  position: relative;
  padding: 16px;

  border-radius: 4px;
  background-color: var(--color-bg-light, white);
  z-index: 100;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  opacity: 0.5;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 1;
  }
`

const OffsetController = styled(MoveController)`
  position: absolute;
  top: 8px;
  left: -72px;

  z-index: 100;

  opacity: 0.5;
  transition: opacity 0.2s ease, background-color 0.2s ease, color 0.2s ease;

  &:hover,
  &:active {
    opacity: 1;
  }

  &:active {
    background-color: var(--color-main, ref);
    color: var(--color-bg-light, white);
  }
`
