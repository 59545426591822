import React from 'react'
import styled from '@emotion/styled'

import { Button } from 'components/Button'
import { Slider } from 'components/Slider'

// https://css-tricks.com/the-lengths-of-css/#article-header-id-4
const mm = (n = 1) => n * 3.78

export const Controls = ({
  scale,
  canUndo,
  canRedo,
  onRotate,
  onMoveX,
  onMoveY,
  onRedo,
  onUndo,
  onReset,
  onScale,
  onCommitScale
}) => (
  <Panel>
    <Section>
      <Heading>Rotation</Heading>
      <Button onClick={() => onRotate(-90)}>-90deg</Button>
      <Button onClick={() => onRotate(-1)}>-1deg</Button>
      <Button onClick={() => onRotate(+1)}>+1deg</Button>
      <Button onClick={() => onRotate(+90)}>+90deg</Button>
    </Section>
    <Section>
      <Heading>Position</Heading>
      <Button onClick={() => onMoveY(mm(-1))}>Up 1mm</Button>
      <Button onClick={() => onMoveY(mm(+1))}>Down 1mm</Button>
      <Button onClick={() => onMoveX(mm(-1))}>Left 1mm</Button>
      <Button onClick={() => onMoveX(mm(+1))}>Right 1mm</Button>
    </Section>
    <Section>
      <Heading>Scale</Heading>
      <Slider
        value={scale}
        min={0.5}
        max={2}
        step={0.01}
        onChange={ev => onScale(parseFloat(ev.target.value))}
        onBlur={onCommitScale}
        onMouseUp={onCommitScale}
      />
    </Section>
    <Section>
      <Heading>Stack</Heading>
      <Button disabled={!canUndo} onClick={onUndo}>
        Undo
      </Button>
      <Button disabled={!canRedo} onClick={onRedo}>
        Redo
      </Button>
      <Button disabled={!canUndo} onClick={onReset}>
        Reset
      </Button>
    </Section>
  </Panel>
)

const Panel = styled.div`
  display: block;
  padding: 32px;

  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
`

const Section = styled.div`
  & + & {
    margin-top: 32px;
  }
`

const Heading = styled.h2`
  margin: 0;
  margin-bottom: 16px;
  font-weight: 500;
`
