import React from 'react'
import styled from '@emotion/styled'

import {ReactComponent as Arrow} from './arrow.svg'

export const MoveController = ({ className }) => (
  <Controller role="button" className={className}>
    <Top/>
    <Right/>
    <Bottom/>
    <Left/>
  </Controller>
)

const StyledArrow = styled(Arrow)`
  position: absolute;
  width: 16px;
  height: 16px;
  fill: currentColor;
`

const Top = styled(StyledArrow)`
  top: 8px;
  left: 50%;
  transform: translateX(-50%) rotate(-90deg);
`

const Bottom = styled(StyledArrow)`
  left: 50%;
  bottom: 8px;
  transform: translateX(-50%) rotate(90deg);
`

const Right = styled(StyledArrow)`
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
`

const Left = styled(StyledArrow)`
  left: 8px;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
`

const Controller = styled.a`
  position: relative;
  display: block;
  width: 64px;
  height: 64px;

  border-radius: 50%;
  background-color: var(--color-bg-light, white);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  user-select: none;
`
